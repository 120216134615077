import React from 'react';
import styled from 'styled-components';
import Doggy from './../atoms/Doggy';
import Towel from './../atoms/Towel';

const ImageWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  max-height: calc(100vw - 10rem);
  margin: 11.8rem auto 5rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    max-width: 81.4rem;
    max-height: 58.4rem;
    margin: 6.2rem auto 15.2rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    max-width: 108.9rem;
    max-height: 78.7rem;
    margin: 0.3rem auto 10rem;
  }
`;

const MainImage = () => (
  <ImageWrapper>
    <Towel />
    <Doggy />
  </ImageWrapper>
);

export default MainImage;
