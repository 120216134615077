import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

const TowelImage = styled.img`
  width: 100%;
  height: auto;
`;

const TowelWrapper = styled.div`
  overflow: hidden;
`;

export const query = graphql`
  {
    file(name: { eq: "towel" }) {
      childImageSharp {
        fluid(maxWidth: 620, maxHeight: 395, quality: 90) {
          src
          srcSet
          sizes
        }
      }
    }
  }
`;

const Towel = () => {
  const data = useStaticQuery(query);
  const { src, srcSet, sizes } = data.file.childImageSharp.fluid;

  return (
    <TowelWrapper>
      <TowelImage
        alt="een stapel handdoeken"
        src={src}
        srcSet={srcSet}
        sizes={sizes}
      />
    </TowelWrapper>
  );
};

export default Towel;
