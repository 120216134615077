import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import MainImage from './../components/molecules/MainImage';
import SEO from '../components/atoms/SEO';

const TextWrapper = styled.div`
  margin: 5rem 0.8rem 5rem 0.8rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    position: absolute;
    top: 15rem;
    margin-top: 0;
    margin-left: 4rem;
    right: 40%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    right: 45%;
    margin-left: 10rem;
    top: 13rem;
  }
`;

const StyledH2 = styled.h2`
  font-family: ${({ theme }) => theme.font.family.bold};
  font-weight: 600;
  font-size: ${({ theme }) => theme.font.size.bigger};
  @media (min-width: ${({ theme }) => theme.breakpoints.s}) {
    font-size: ${({ theme }) => theme.font.size.big};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    font-size: ${({ theme }) => theme.font.size.superbig};
  }
`;

const StyledP = styled.p`
  font-weight: 300;
  @media (min-width: ${({ theme }) => theme.breakpoints.m}) {
    font-size: ${({ theme }) => theme.font.size.big};
  }
`;

const query = graphql`
  {
    datoCmsIndex {
      title
      mainText
    }
  }
`;

const IndexPage: React.FC = () => {
  const data = useStaticQuery(query);
  const { title, mainText } = data.datoCmsIndex;
  return (
    <>
      <SEO
        title="Hondentrimsalon Happy Puppy"
        description="Hondentrimsalon Happy Puppy - Maria Hoop"
      />
      <MainImage />
      <TextWrapper>
        <StyledH2>{title}</StyledH2>
        <StyledP>{mainText}</StyledP>
      </TextWrapper>
    </>
  );
};

export default IndexPage;
