import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

const DoggyImage = styled.img`
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
`;

const DoggyWrapper = styled.div`
  overflow: hidden;
`;

export const query = graphql`
  {
    file(name: { eq: "doggy" }) {
      childImageSharp {
        fluid(maxWidth: 749, maxHeight: 787, quality: 90) {
          src
          srcSet
          sizes
        }
      }
    }
  }
`;

const Doggy = () => {
  const data = useStaticQuery(query);
  const { src, srcSet, sizes } = data.file.childImageSharp.fluid;
  return (
    <DoggyWrapper>
      <DoggyImage
        alt="de hond kijkt op"
        src={src}
        srcSet={srcSet}
        sizes={sizes}
      />
    </DoggyWrapper>
  );
};

export default Doggy;
